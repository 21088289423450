import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  description?: string
  label?: string
  image?: { [key: string]: any }
  title?: string
  type?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  label,
  image,
  title,
  type = 'full',
}: Props) {
  return (
    <Container className={`type-${type}`}>
      <FadeInOutAnimation>
        <ImageWrap className="advanced-paragraph-images">
          {image ? (
            <LazyLoad once>
              <BackgroundImage
                className="advanced-paragraph-bg"
                style={{ backgroundImage: `url(${image.src})` }}
              />
              <MainImage {...image} />
            </LazyLoad>
          ) : null}
          <Wrapper className="advanced-paragraph-wrapper" dial={5}>
            <Text>
              {label ? <Label>{label}</Label> : null}
              {title ? <Title>{title}</Title> : null}
            </Text>
          </Wrapper>
        </ImageWrap>
      </FadeInOutAnimation>
      {description ? (
        <FadeInOutAnimation>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInOutAnimation>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  margin-top: 5.25rem;
  &:first-of-type {
    margin-top: 0;
  }
  &.type-half {
    width: calc(50% - 48px);
    .advanced-paragraph-images {
      padding-bottom: 78%;
      .advanced-paragraph-bg {
        width: calc(50% + 1.75rem);
      }
      .advanced-paragraph-wrapper {
        width: calc(100% - 2.5rem);
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 3rem;
    &.type-half {
      width: 100%;
      .advanced-paragraph-images {
        padding-bottom: 66%;
        .advanced-paragraph-wrapper {
          width: 100%;
        }
      }
    }
  }
`

const ImageWrap = styled.div`
  overflow: hidden;
  position: relative;
  padding-bottom: 36%;
  &:before {
    content: '';
    width: calc(100% - 2.5rem);
    height: calc(100% - 5rem);
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    &:before {
      width: calc(100% - 1.5rem);
      height: calc(100% - 3rem);
      top: 1.5rem;
      left: 1.5rem;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 66%;
    &:before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`

const BackgroundImage = styled.span`
  width: 50%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 767px) {
    display: none;
  }
`

const MainImage = styled(Image)`
  width: calc(100% - 2.5rem);
  height: calc(100% - 5rem);
  object-fit: cover;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;

  @media (max-width: 1023px) {
    width: calc(100% - 1.5rem);
    height: calc(100% - 3rem);
    top: 1.5rem;
    left: 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled(FlexBox)`
  width: calc(100% - 5rem);
  height: calc(100% - 5rem);
  padding: 0 1.5rem;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 2;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Text = styled.div`
  text-align: center;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: 1px;
  opacity: 0.5;
  margin-bottom: 0.5625rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 1.875rem;

  @media (max-width: 767px) {
    margin-top: 0;
    padding: 0.75rem 1.5rem 0;
  }
`
