import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Paragraph, Props as ParagraphProps } from './Paragraph'

export interface Props {
  paragraphs: ParagraphProps[]
}

export const AdvancedParagraphs = memo(function AdvancedParagraphs({
  paragraphs,
}: Props) {
  if (!paragraphs) {
    return null
  }

  return (
    <Container row wrap space="between" tag="section">
      {paragraphs.map((item, index) => (
        <Paragraph key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  max-width: 73.5rem;
  margin: 7.5rem auto 9.75rem;
  padding: 0 1.5rem;

  @media (max-width: 767px) {
    margin: 5.25rem auto;
    padding: 0;
  }
`
